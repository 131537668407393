import store from '../store';

export const inputTypes = {
    TEXT: 'text',
    EMAIL: 'email',
    DATE: 'date',
    PARTNER: 'partner',
};

export const capabilitiesFields = {
    emailAddress: {
        label: "Your Email Address",
        type: inputTypes.EMAIL,
        required: false,
    },
    partnerName: {
        label: "Partner Name",
        type: inputTypes.PARTNER,
        required: false,
    },
    clientName: {
        label: "Client (Account) Name",
        type: inputTypes.TEXT,
        required: true,
    },
    clientRef: {
        label: "Client ID/Reference",
        type: inputTypes.TEXT,
        required: false,
    },
    programmeName: {
        label: "Programme Name",
        type: inputTypes.TEXT,
        required: false,
    },
    inceptionDate: {
        label: "Inception Date",
        type: inputTypes.DATE,
        required: false,
    },
    underwriterName: {
        label: "Underwriter Name",
        type: inputTypes.TEXT,
        required: false,
    },
    brokerName: {
        label: "Broker Name",
        type: inputTypes.TEXT,
        required: false,
    },
    referenceNumber: {
        label: "Reference Number",
        type: inputTypes.TEXT,
        required: false,
    },
};

const FeaturesMixin = {
    methods: {
        isClient(...clientKeys) {
            for (let clientKey of clientKeys) {
                if (process.env.VUE_APP_ICEDE_CLIENT === clientKey) {
                    return true
                }
            }
            return false
        },
        inquiryOnlyMode() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingComplexKeyAuth() {
            return parseInt(process.env.VUE_APP_USING_COMPLEX_KEY_AUTH) === 1;
        },
        usingPricingMatrix() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        usingCoverageElements() {
            return !this.usingAMENAElements();
        },
        usingPolicyTypeSelection() {
            return !this.usingAMENAElements();
        },
        usingTacitRenewal() {
            return this.usingAMENAElements();
        },
        usingContribution() {
            return this.usingAMENAElements();
        },
        usingIssuingOffice() {
            return this.usingAMENAElements() && store.getters.userIsProducing;
        },
        usingPartnerDropdown() {
            return this.usingAMENAElements();
        },
        usingDateOfRate() {
            return !this.usingAMENAElements();
        },
        usingSingleDetailsPane() {
            return this.usingAMENAElements();
        },
        usingMinimumRequirements() {
            return !this.usingAMENAElements();
        },
        hasExtraHeaderRow() {
            return !this.usingAMENAElements();
        },
        usingFOSNonAdm() {
            return !this.usingAMENAElements();
        },
        usingPolicyLimit() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingSingleHeader() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_DEFAULT);
        },
        usingFinanceElements() {
            return !this.usingAMENAElements();
        },
        usingKeyContacts() {
            return !this.usingAMENAElements();
        },
        usingAMENAElements() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingCountryPartnerInput() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingCentralColletion () {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingMaxPolicyLimit() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingLocalTokenAuth() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingProducingOfficeDropdown() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingPolicyLimitMaxLimit() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        canEditUserDetails() {
            // Don't think any deployments need this at the moment, but without this it is flashing up before 
            //  redirecting during the login process
            return this.usingComplexKeyAuth() && false;
        },
        getIndicativePremiumLabel() {
            if (this.usingAMENAElements()) {
                return 'Gross Premium';
            } else {
                return 'Indicative Premium';
            }
        },
        getLocalPolicyLabel() {
            if (this.usingAMENAElements()) {
                return 'Local Capability';
            } else {
                return 'Local Policy';
            }
        },
        getInstructCoordinatorLabel() {
            if (this.usingAMENAElements()) {
                return 'Input Premium Data by Country';
            } else {
                return 'Instruct Coordinator';
            }
        },
        getClientNameLabel() {
            if (this.usingAMENAElements()) {
                return 'Customer (Account) Name';
            } else {
                return 'Client (Account) Name';
            }
        },
        getInsertClientNameLabel() {
            return 'Insert ' + this.getClientNameLabel();
        },
        getNavigationTitle() {
            if (this.usingAMENAElements()) {
                return 'AMENA - Business Pipeline - New Inquiry Creation';
            } else {
                return 'iCede Programme Inquiry';
            }
        },
        getInquiryTitle() {
            if (this.usingAMENAElements()) {
                return 'Request Details';
            } else {
                return 'Multinational Programme - Inquiry Tool';
            }
        },
        canSaveReport() {
            return !this.usingAMENAElements() && !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        capabilitiesPDFExportEnabled() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        activeCapabilitiesFields() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO)) {
                return {
                    underwriterName: capabilitiesFields.underwriterName,
                    emailAddress: capabilitiesFields.emailAddress,
                    brokerName: capabilitiesFields.brokerName,
                    clientName: capabilitiesFields.clientName,
                    programmeName: capabilitiesFields.programmeName,
                    referenceNumber: capabilitiesFields.referenceNumber,
                    inceptionDate: capabilitiesFields.inceptionDate,
                };
            }
            let fields = {
                clientName: capabilitiesFields.clientName,
                clientRef: capabilitiesFields.clientRef,
                inceptionDate: capabilitiesFields.inceptionDate,
            };
            if (this.usingPartnerDropdown()) {
                fields[partnerName] = capabilitiesFields.partnerName;
            }
            fields['programmeName'] = capabilitiesFields.programmeName;
            return fields;
        },
    }
}

export default FeaturesMixin
