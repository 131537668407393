<template>
  <div class="row">
    <div class="col contact-buttons">
      <button class="btn btn-primary btn-fill btn-xs" @click="downloadTemplate">
        <i class="fa fa-download"></i>&nbsp;Download Template
      </button>
      &nbsp;
      <button class="btn btn-success btn-fill btn-xs" @click="showUploadModal">
        <i class="fa fa-upload"></i>&nbsp;Upload Completed Template
      </button>
    </div>
    <b-modal id="modal-contacts-upload" hide-footer ref="modal-contacts-upload">
      <template v-slot:modal-header>
        <h3 class="my-0">
          <i class="fa fa-upload"></i>&nbsp;Upload Local Contacts Template
        </h3>
      </template>
      <div class="form-group">
        <label for="inpTemplateFile">Contacts Template</label>
        <input
          type="file"
          class="form-control"
          id="inpTemplateFile"
          @change="handleTemplateUpload"
        />
        <small v-if="error" class="text-danger">{{ error }}</small>
      </div>
      <div class="row border-top pt-2">
        <div class="col text-right">
          <button
            class="btn btn-primary btn-sm btn-fill"
            @click="$refs['modal-contacts-upload'].hide()"
          >
            <i class="fa fa-check"></i>&nbsp;Close
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.contact-buttons .btn-xs {
  font-size: 0.9em;
}
</style>

<script>
import ExcelJS from "exceljs";

const ID_COLUMN = "A";
const COUNTRY_COLUMN = "B";
const TYPE_COLUMN = "C";
const INCLUDE_BROKER_COLUMN = "D";
const CONT_NAME_COLUMN = "E";
const COMP_NAME_COLUMN = "F";
const ADDRESS_COLUMN = "G";
const TELEPHONE_COLUMN = "H";
const EMAIL_COLUMN = "I";

const USER_ROW_START = 10;

export default {
  name: "KCTemplate",
  data: () => ({
    workbook: null,
    error: null
  }),
  methods: {
    downloadTemplate() {
      const url =
        "http://system.test:8088/templates/local_contacts_template.xlsx";
      const req = new XMLHttpRequest();
      req.open("get", url, true);
      req.responseType = "arraybuffer";

      const context = this;
      req.onload = async function() {
        const data = new Uint8Array(req.response);
        context.workbook = new ExcelJS.Workbook();
        await context.workbook.xlsx.load(data);
        context.generateTemplate();
        context.sendTemplateToUser();
      };

      req.send();
    },
    generateTemplate() {
      // Add a row for client and broker for each policy (country)
      const wb = this.workbook;
      wb.creator = "iCede Solutions Ltd.";
      wb.created = new Date();
      wb.modified = new Date();
      const ws = wb.worksheets[0];
      this.$store.getters.localContactDetails.forEach((contact, ind) => {
        const matches = contact.id.match(/client/);
        const isClient = matches !== null;
        // Contact Row ID - Hidden to user
        ws.getCell(ID_COLUMN + (USER_ROW_START + ind)).value = contact.id;
        // Fill in the excel template with the data entered in the view before exporting
        if (isClient) {
          ws.getCell(COUNTRY_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[0].value;
          ws.getCell(TYPE_COLUMN + (USER_ROW_START + ind)).value = "Client";
          ws.getCell(CONT_NAME_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[3].value;
          ws.getCell(COMP_NAME_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[4].value;
          ws.getCell(ADDRESS_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[5].value;
          ws.getCell(TELEPHONE_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[6].value;
          ws.getCell(EMAIL_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[7].value;
        } else {
          ws.getCell(TYPE_COLUMN + (USER_ROW_START + ind)).value = "Broker";
          // Include Local Broker?
          ws.getCell(
            INCLUDE_BROKER_COLUMN + (USER_ROW_START + ind)
          ).value = contact.visible ? "Y" : "N";
          ws.getCell(CONT_NAME_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[2].value;
          ws.getCell(COMP_NAME_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[3].value;
          ws.getCell(ADDRESS_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[4].value;
          ws.getCell(TELEPHONE_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[5].value;
          ws.getCell(EMAIL_COLUMN + (USER_ROW_START + ind)).value =
            contact.data[6].value;
        }
      });

      ws.addConditionalFormatting({
        ref:
          COUNTRY_COLUMN +
          USER_ROW_START +
          ":" +
          EMAIL_COLUMN +
          (USER_ROW_START + this.$store.getters.policyCountries.length * 2),
        rules: [
          {
            type: "expression",
            formulae: [
              "ROW()<" + (10 + this.$store.getters.policyCountries.length * 2)
            ],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: { argb: "F8F8F8" }
              }
            }
          }
        ]
      });
    },
    sendTemplateToUser() {
      const context = this;
      this.workbook.xlsx.writeBuffer().then(function(data) {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "inquiry_local_contacts_template.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
        context.workbook = null;
      });
    },
    showUploadModal() {
      this.$refs["modal-contacts-upload"].show();
    },
    handleTemplateUpload(e) {
      // Get the file selected by the user from the input
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      const context = this;
      reader.onload = async () => {
        context.workbook = new ExcelJS.Workbook();
        await context.workbook.xlsx.load(reader.result);

        const ws = context.workbook.worksheets[0];
        let valid = true;
        const contacts = [];
        const brokersToRemove = [];
        context.$store.getters.policyCountries.forEach((country, ind) => {
          // Check that the country on this row matches up with the one we expect from the local policy store
          if (
            ws.getCell(COUNTRY_COLUMN + (USER_ROW_START + ind * 2)).value !==
            country
          ) {
            console.error(
              "This template does not seem to match the current Programme Inquiry\nFound in cell: " +
                ws.getCell(COUNTRY_COLUMN + (USER_ROW_START + ind * 2)).value +
                "\nExpected: " +
                country
            );
            this.error =
              "This template does not match the current Programme Inquiry. Please re-download the template and try again";
            valid = false;
            return;
          }
          // If we hit an error on a previous row, do not continue
          if (!valid) {
            return;
          }

          const clientRow = [],
            brokerRow = [];
          for (const col of [
            ID_COLUMN,
            CONT_NAME_COLUMN,
            COMP_NAME_COLUMN,
            ADDRESS_COLUMN,
            TELEPHONE_COLUMN,
            EMAIL_COLUMN
          ]) {
            clientRow.push(ws.getCell(col + (USER_ROW_START + ind * 2)).value);
            brokerRow.push(
              ws.getCell(col + (USER_ROW_START + ind * 2 + 1)).value
            );
          }
          contacts.push(clientRow);
          contacts.push(brokerRow);

          const brokerIncluded =
            ws.getCell(INCLUDE_BROKER_COLUMN + (USER_ROW_START + ind * 2 + 1))
              .value === "Y";
          if (!brokerIncluded) {
            brokersToRemove.push(ind * 2 + 1);
          }
        });
        // If no errors were found, we can now import the contacts
        if (valid) {
          // Reset the contact details before import
          await context.$store.dispatch("generateContactDetails");
          contacts.forEach((ar, row) => {
            const id = ar[0];
            ar.forEach((val, col) => {
              if (col !== 0) {
                context.$store.commit("setContactDetail", {
                  id: id,
                  col: col + (row % 2 === 0 ? 2 : 1), // Client rows are even - In the contact store the client row has the country as its first element
                  value: val?.text ? val.text : val,
                });
              }
            });
          });
          this.$store.commit("removeBrokerRows", { rows: brokersToRemove });
          // Hide the modal and show a toast message when successfully imported
          this.$refs["modal-contacts-upload"].hide();
          this.$toasted.success(
            "Successfully imported your Local Contacts template"
          );
        }
      };
    }
  }
};
</script>
