<template>
  <input
    class="form-control"
    :class="{'border-danger': variant === 'danger', 'text-danger': variant === 'danger'}"
    :value="disabled ? ' ' : computedDisplayValue"
    :placeholder="placeholder"
    :id="id"
    :disabled="disabled"
    @input="onInput($event)"
    @focusin="focusIn"
    @focusout="focusOut"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: "CurrencyFormattedInput",
  props: {
    value: [String, Number],
    placeholder: String,
    id: String,
    inputClass: String,
    disabled: Boolean,
    variant: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      displayValue: "",
      rawValue: "",
      formattedValue: "",
      focused: false
    };
  },
  computed: {
    computedDisplayValue() {
      if (this.focused) {
        return this.value;
      } else {
        return this.format(this.value);
      }
    }
  },
  methods: {
    format(value) {
      if (value !== "" && !isNaN(value)) {
        return new Intl.NumberFormat("en-gb", {
          maximumFractionDigits: 2
        }).format(value);
      } else {
        return value;
      }
    },
    onInput(event) {
      const numValue = event.target.value.replace(/[^\d,.]/g, "");
      this.formattedValue = this.format(numValue);
      this.rawValue = numValue;
      this.$emit("input", numValue);
    },
    focusIn() {
      this.focused = true;
    },
    focusOut(event) {
      const numValue = event.target.value.replace(/[^\d,.]/g, "");
      this.focused = false;
      this.$emit('focusout', numValue);
    }
  }
};
</script>
