// import axios from "axios";

import axios from "axios";

const randRange = function(min, max) {
  return min + (max - min) * Math.random();
};

const generateCoverage = function(name) {
  const occurrenceLimit = randRange(2000000, 10000000);
  const aggregateLimit = occurrenceLimit * randRange(1.5, 3);
  const minDeductible = randRange(9000, 20000);
  const maxDeductible = minDeductible + 5000 + randRange(0, 50000);
  return {
    name: name,
    occurrenceLimit,
    aggregateLimit,
    maxDeductible,
    minDeductible,
    percentageDeductible: randRange(0.5, 6)
  };
};

export const covers = {
  state: () => ({
    coverTemplates: [],
    companyPerils: [],
    selectedTemplateId: 1,
    lastCustomPerilId: 0
  }),
  mutations: {
    setTemplates(state, payload) {
      state.coverTemplates = payload.coverTemplates;
    },
    setSelectedTemplate(state, payload) {
      state.selectedTemplateId = payload.selectedTemplateId;
    },
    updateCoverFieldValue(state, payload) {
      if (payload.all) {
        state.coverTemplates
          .find(template => template.id === state.selectedTemplateId)
          .covers.forEach(
            cover => (cover.data[payload.column] = payload.value)
          );
        return true;
      } else {
        const cover = state.coverTemplates
          .find(template => template.id === state.selectedTemplateId)
          .covers.find(cover => cover.id === payload.coverId);
        if (cover) {
          cover.data[payload.column] = payload.value;
          return true;
        } else {
          return false;
        }
      }
    },
    selectCover(state, payload) {
      const row = state.coverTemplates
        .find(template => template.id === state.selectedTemplateId)
        .covers.find(cover => cover.id === payload.id);
      row.selected = !row.selected;
    },
    addCover(state, payload) {
      const template = state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      );
      template.covers.push(payload.cover);
    },
    swapCovers(state) {
      const covers = state.coverTemplates
        .find(template => template.id === state.selectedTemplateId)
        .covers.filter(cover => cover.selected);
      covers.forEach(cover => {
        cover.data.included = !cover.data.included;
        cover.selected = false;
      });
    },
    swapCover(state, payload) {
      const row = state.coverTemplates
        .find(template => template.id === state.selectedTemplateId)
        .covers.find(cover => cover.id === payload.id);
      row.data.included = !row.data.included;
    },
    changeTemplate(state, payload) {
      const template = state.coverTemplates.find(
        template => template.name === payload.newTemplate
      );
      if (template) {
        state.selectedTemplateId = template.id;
      }
    },
    setCompanyPerils(state, payload) {
      state.companyPerils = payload.perils;
    },
    incrementLastCustomPerilId(state) {
      state.lastCustomPerilId++;
    },
    hideColumn(state, payload) {
      const template = state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      );
      const flagName = payload.column + "Visible";
      if (Object.prototype.hasOwnProperty.call(template, flagName)) {
        template[flagName] = false;
      }
    }
  },
  actions: {
    async getCoverageTemplates({ commit, dispatch, rootGetters }) {
      try {
        const policyTemplates = await axios.get(
          process.env.VUE_APP_INQUIRY_API_ENDPOINT +
            "/wiki/coverageTemplates/" +
            rootGetters.classType.policy_class_type_caption,
          {
            withCredentials: true
          }
        );

        if (policyTemplates.status === 200 && policyTemplates.data.success && policyTemplates.data.templates.length > 0) {
          // Add the column visibility flags before sending to store
          policyTemplates.data.templates.forEach(template => {
            template.occurrenceVisible = true;
            template.aggregateVisible = true;
            template.maxVisible = true;
            template.minVisible = true;
            template.percentageVisible = true;
            // Set selected flags
            template.covers.forEach(cover => {
              cover.selected = false;
            });
          });
          commit("setTemplates", { coverTemplates: policyTemplates.data.templates });
          commit("setSelectedTemplate", { selectedTemplateId: 1 });
          dispatch("updatePolicyDataFromCoverage", {
            field: "all",
            coverId: "all"
          });
          dispatch("getCompanyPerils");
        } else {
          console.error(policyTemplates);
          console.error("Template request error: Success =", policyTemplates.data.success, ", templates found =", policyTemplates.data.templates?.length);
        }
      } catch (err) {
        console.log("AXIOS coverage templates error");
        console.error(err);
      }
    },
    async getCompanyPerils({ commit, rootGetters }) {
      try {
        const companyPerils = await axios.get(
          process.env.VUE_APP_INQUIRY_API_ENDPOINT +
            "/wiki/companyPerils/" +
            rootGetters.classType.policy_class_type_caption,
          {
            withCredentials: true
          }
        );

        if (companyPerils.status === 200) {
          commit("setCompanyPerils", { perils: companyPerils.data });
        } else {
          console.error(companyPerils);
          console.error("There was an error with the above request");
        }
      } catch (err) {
        console.log("AXIOS coverage templates error");
        console.error(err);
      }
    },
    updateCoverNumbers({ commit, dispatch }, payload) {
      //coverId, column, value) {
      if (payload.coverId === "all") {
        commit("updateCoverFieldValue", {
          all: true,
          column: payload.column,
          value: payload.value
        });
        dispatch("updatePolicyDataFromCoverage", {
          field: payload.column,
          coverId: "all"
        });
      } else {
        commit("updateCoverFieldValue", {
          all: false,
          column: payload.column,
          value: payload.value,
          coverId: payload.coverId
        });
        dispatch("updatePolicyDataFromCoverage", {
          field: payload.column,
          coverId: payload.coverId
        });
      }
    },
    selectCoverRow({ commit }, payload) {
      commit("selectCover", payload);
    },
    swapSelectedCovers({ commit, dispatch }) {
      commit("swapCovers");
      dispatch("swapPolicyCovers");
    },
    swapCover({ commit, dispatch }, payload) {
      commit("swapCover", payload);
      dispatch("swapPolicyCover", payload);
    },
    changeTemplate({ commit }, payload) {
      commit("changeTemplate", payload);
    },
    loadCoverStateFromStorage({ commit }, payload) {
      commit("setTemplates", payload.covers);
      commit("setSelectedTemplate", payload.covers);
    },
    addCover({ commit }, payload) {
      // TODO: Is this correct? -> Both include and exclude have been allowed on custom perils,
      //  as we don't know the local office's preference straight away
      const newCover = {
        id: payload.id,
        selected: false,
        data: {
          name: payload.name,
          aggregateLimit: 0,
          maxDeductible: 0,
          minDeductible: 0,
          occurrenceLimit: 0,
          percentageDeductible: 0,
          allowInclude: true,
          allowExclude: true,
          included: 1
        }
      };

      commit("addCover", { cover: newCover });
      commit("addPolicyCover", { cover: newCover });
    }
  },
  getters: {
    coverStateForStorage: state => {
      return {
        coverTemplates: state.coverTemplates,
        selectedTemplateId: state.selectedTemplateId
      };
    },
    selectedTemplate: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ),
    templateNames: state => state.coverTemplates.map(template => template.name),
    companyPerils: state => state.companyPerils,
    lastCustomPerilId: state => state.lastCustomPerilId,
    occurrenceVisible: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ).occurrenceVisible,
    aggregateVisible: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ).aggregateVisible,
    maxVisible: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ).maxVisible,
    minVisible: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ).minVisible,
    percentageVisible: state =>
      state.coverTemplates.find(
        template => template.id === state.selectedTemplateId
      ).percentageVisible
  }
};
