var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-add-cover",attrs:{"id":"modal-add-cover","size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"my-0"},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add Cover")]),_c('button',{staticClass:"pull-right btn btn-xs btn-secondary",on:{"click":_vm.handleCancel}},[_c('i',{staticClass:"fa fa-times fa-2x"})])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col"},[_c('label',{attrs:{"for":"inpCompanyPeril"}},[_vm._v("Peril Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.perilName),expression:"perilName"}],staticClass:"form-control",attrs:{"id":"inpCompanyPeril","type":"text","placeholder":"Enter new peril name","disabled":!_vm.editingCustomPeril},domProps:{"value":(_vm.perilName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.perilName=$event.target.value}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col"},[_c('label',{attrs:{"for":"inpCompanyPerilDescription"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"id":"inpCompanyPerilDescription","type":"text","placeholder":_vm.editingCustomPeril
                ? 'Enter new peril description (optional)'
                : '',"disabled":!_vm.editingCustomPeril,"rows":"8"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col"},[(_vm.perilExistsOnPolicy(_vm.perilId))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
              title: 'This peril already exists on the policy cover',
              variant: 'info'
            }),expression:"{\n              title: 'This peril already exists on the policy cover',\n              variant: 'info'\n            }",modifiers:{"hover":true,"top":true}}],staticStyle:{"display":"inline-block"}},[_c('button',{staticClass:"btn btn-success btn-fill",attrs:{"disabled":""}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add ")])]):_c('button',{staticClass:"btn btn-success btn-fill",on:{"click":_vm.addPerilToCoverage}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-warning ml-1",on:{"click":_vm.handleCancel}},[_c('i',{staticClass:"fa fa-window-close"}),_vm._v(" Cancel ")])])])]),_c('div',{staticClass:"col"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" Create a new peril, or select one from the list below. ")]),_c('button',{staticClass:"btn btn-primary btn-sm mb-2",attrs:{"disabled":_vm.perilId.toString().startsWith('custom')},on:{"click":_vm.startCustomPeril}},[_c('i',{staticClass:"fa fa-pen"}),_vm._v(" New Peril ")]),_c('div',{attrs:{"id":"companyPerilContainer"}},[_c('ul',_vm._l((_vm.perils),function(peril){return _c('li',{class:{
              'selected-peril': _vm.perilId === peril.coverage_company_peril_id,
              'peril-added': _vm.perilExistsOnPolicy(
                peril.coverage_company_peril_id
              )
            },on:{"click":function($event){return _vm.setSelectedPeril(peril.coverage_company_peril_id)}}},[_vm._v(" "+_vm._s(peril.peril_caption)+" ")])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }