import Vue from "vue";
import VueRouter from "vue-router";
import CountrySelector from "../components/CountrySelector";
import CapabilitiesChecker from "../components/CapabilitiesChecker";
import ProgrammeInstructions from "../components/ProgrammeInstructions";
import CoverSelect from "../components/CoverSelect";
import CoverReview from "../components/CoverReview";
// import Auth from "../views/Auth";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/",
    redirect: "/inquiry"
  },
  {
    path: "/inquiry",
    component: () =>
      import(/* webpackChunkName: "inquiry" */ "../views/Home.vue"),
    children: [
      {
        path: "",
        redirect: "countrySelect"
      },
      {
        path: "countrySelect",
        component: CountrySelector,
        name: "Country Select",
        props: true
      },
      {
        path: "capabilities",
        component: CapabilitiesChecker,
        name: "Capabilities",
        props: true
      },
      {
        path: "programmeInstructions",
        component: ProgrammeInstructions,
        name: "Programme Instructions",
        props: true
      },
      {
        path: "coverSelect",
        component: CoverSelect,
        name: "Cover Select",
        props: true
      },
      {
        path: "coverReview",
        component: CoverReview,
        name: "Cover Review",
        props: true
      }
    ]
  },
  {
    path: "/programmes",
    name: "Programmes",
    component: () =>
      import(/* webpackChunkName: "programmes" */ "../views/Programmes.vue")
  },
/*  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" * / "../views/About.vue")
} */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "nav-item active"
});

/**
 * Prevent VueRouter from throwing JS errors for harmless navigation failures.
 * Not necessary on the latest versions but we can't ugprade to Vue3 at this stage (due to Bootstrap)
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
        || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
      console.log("Suppressed navigation failure: " + err);
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }

  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
        || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
      console.log("Suppressed navigation failure: " + err);
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // If the user is not logged in and routes are locked in .env -> redirect to login page
  if (
    process.env.VUE_APP_LOCK_ROUTES_IF_NO_AUTH === "1" &&
    to.name !== "Login" &&
    to.path !== "/login" &&
    !store.getters.isLoggedIn
  ) {
    if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH !== "1") {
      Vue.toasted.show("Please login before starting a Programme Inquiry", {
        type: "default",
        singleton: "true"
      });
    }
    store.dispatch('logout'); // Comment this out if you experience local development route redirect issues.
    next("/login");
  } else {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.name);

    document.title = process.env.VUE_APP_TITLE;

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
      if (nearestWithTitle.name === "Login" && store.getters.isLoggedIn) {
        nearestWithTitle.name = "User";
      }
      document.title = nearestWithTitle.name + " - " + document.title;
    }

    console.log("%cChanging route from " + from.path + " to " + to.path + "...", "font-size: 1.2em; color: rgb(200, 255, 200);");

    next();
  }
});

export default router;
