import axios from "axios";

export const countries = {
  state: () => ({
    countries: [],
    loadingCountries: false
  }),
  mutations: {
    clearCountries(state) {
      state.countries = [];
    },
    addCountries(state, payload) {
      payload.countries.forEach(function(country) {
        // Only add countries that we do not already have.
        let foundCountry = state.countries.find(existingCountry => existingCountry.country_id === country.country_id);

        if (foundCountry === undefined) {
          state.countries.push(country);
        }
      });
    },
    setCountries(state, payload) {
      state.countries = payload.countries;
    },
    setLoadingCountries(state, { loading }) {
      state.loadingCountries = loading;
    },
    checkUncheckCountry(state, payload) {
      if (payload.countryId === "all") {
        let countries = state.countries;
        if (payload.continent) {
          countries = state.countries.filter(
            country => country.continent === payload.continent
          );
        }
        countries.forEach(country => {
          if (
            !country.noRepresentation &&
            country.checked !== payload.checked
          ) {
            country.checked = payload.checked;
          }
        });
      } else {
        const country = state.countries.find(
          country => country.country_id === payload.countryId
        );
        if (country !== undefined) {
          country.checked = payload.checked;
        }
      }
    }
  },
  actions: {
    async loadCountriesForContinent({ state, commit }, payload) {
      let continent = payload ? payload.continent : "";

      let countries = state.countries.filter(
          country => country.continent === continent || continent === "All"
      );

      if (continent.length !== 0) {
        continent = "/" + continent;
      }
      if (countries.length === 0) {
        try {
          commit("setLoadingCountries", { loading: true });
          let { data } = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/country" + continent,
            {
              withCredentials: true
            }
          );
          data = data.map(function(country) {
            country.checked = false;
            return country;
          });
          countries = data;
        } catch (err) {
          console.error(err);
        }

        commit("setLoadingCountries", { loading: false });
      }

      commit({
        type: "addCountries",
        countries
      });
    },
    selectDeselectCountry({ commit, getters }, payload) {
      commit("checkUncheckCountry", payload);
      commit("setPolicyDetail", {
        emailAddress: getters.user?.email,
      });
    },
    async loadCountryStateFromStorage({ dispatch, commit }, payload) {
      // TODO: check how long this takes... May have to rewrite this
      //  s.t. countries are selected as and when they are loaded by user interaction
      // All continents must be loaded...
      await dispatch("loadAllCountries");
      // Uncheck all countries
      commit("checkUncheckCountry", { countryId: "all", checked: false });
      // Check all countries from storage
      for (let countryId of payload.countries.selectedCountryIds) {
        commit("checkUncheckCountry", { countryId: countryId, checked: true });
      }
    },
    async loadAllCountries({ dispatch, commit }) {
      commit("clearCountries");
      await dispatch("loadCountriesForContinent");
    }
  },
  getters: {
    countryStateForStorage: state => {
      // Only need to store selected country IDs
      const countryIds = state.countries
        .filter(country => country.checked)
        .map(country => country.country_id);
      return { selectedCountryIds: countryIds };
    },
    getCountriesByContinent: state => continent => {
      return state.countries.filter(
        country => country.continent === continent || continent === "All"
      ).sort((a, b) => a.country_name.localeCompare(b.country_name));
    },
    getSelectedCountries: state =>
      state.countries.filter(country => country.checked).sort((a, b) => a.country_name.localeCompare(b.country_name)),
    getCountryList: state =>
      state.countries.reduce((list, country) => {
        if (country.checked) {
          return list + (list !== "" ? ", " : "") + country.country_name;
        } else {
          return list;
        }
      }, ""),
    loadingCountries: state => state.loadingCountries,
    officesForCountry: state => country_id => {
      const country = state.countries.find(
        country => country.country_id === country_id
      );
      return country.issuingOffices ?? [];
    }
  }
};
