<template>
  <div
    v-if="tooltipActive"
    v-b-tooltip="tooltipConfig"
    style="display: inline-block"
    class="textTruncatedContainer"
  >
    <component :is="element" v-html="processedText">
      {{ processedText }}
    </component>
  </div>
  <div v-else style="display: inline-block">
    <component :is="element" v-html="text">
      {{ text }}
    </component>
  </div>
</template>

<style scoped>
.textTruncatedContainer {
  cursor: pointer;
}
</style>

<script>
export default {
  name: "TextTruncated",
  props: {
    text: {
      type: String,
      default: "Missing Text!"
    },
    element: {
      type: String,
      default: "span"
    },
    maxLength: {
      type: Number,
      default: 40
    },
    tooltipVariant: {
      type: String,
      default: "info"
    },
    tooltipPosition: {
      type: String,
      default: "top"
    }
  },
  computed: {
    processedText() {
      let finalText = this.text;
      if (finalText.length > this.maxLength) {
        finalText = finalText.substr(0, this.maxLength) + "...";
      }
      return finalText;
    },
    tooltipConfig() {
      return {
        title: this.text,
        trigger: "hover",
        variant: this.tooltipVariant,
        placement: this.tooltipPosition
      };
    },
    tooltipActive() {
      return this.text.length > this.maxLength;
    }
  }
};
</script>
