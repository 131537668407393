<template>
  <div class="cover-details">
    <div class="form-group row">
      <label for="selPolicyTemplate" class="col-sm-2 col-form-label"
      >Policy Template</label
      >
      <div class="col-sm-5">
        <v-select
            id="selPolicyTemplate"
            :options="templateNames"
            :value="selectedTemplateName"
            label="name"
            @input="changeTemplate"
        >
        </v-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inpPolicyLimit" class="col-sm-2 col-form-label"
      >Overall Policy Limit</label
      >
      <div class="col-sm-5">
        <currency-formatted-input
            :value="$store.getters.getPolicyLimit"
            @input="value => ($store.commit('setPolicyLimit', {policyLimit: value}))"
            placeholder="Policy Limit"
            id="inpPolicyLimit"
        >
        </currency-formatted-input>
      </div>
    </div>
    <div class="form-group row">
      <label for="inpPolicyDeductible" class="col-sm-2 col-form-label"
      >Overall Policy Deductible</label
      >
      <div class="col-sm-5">
        <currency-formatted-input
            :id="'inpPolicyDeductible'"
            :value="overallPolicyDeductible"
            @input="value => (overallPolicyDeductible = value)"
        ></currency-formatted-input>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Claims Type</label>
      <div class="col-sm-5">
        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              name="radClaimsType"
              id="radClaimsTypeMade"
              value="made"
              checked
          />
          <label class="form-check-label" for="radClaimsTypeMade"
          >Made</label
          >
        </div>
        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              name="radClaimsType"
              id="radClaimsTypeOccurring"
              value="occurring"
          />
          <label class="form-check-label" for="radClaimsTypeOccurring"
          >Occurring</label
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FeaturesMixin from "@/mixins/features.mixin";
import CurrencyFormattedInput from "./CurrencyFormattedInput";
export default {
  name: "CoverSelect",
  components: {
    CurrencyFormattedInput,
  },
  props: {},
  data: () => {
    return {
      commonOccurrence: "",
      commonAggregate: "",
      commonMax: "",
      commonMin: "",
      commonPercentage: "",
      overallPolicyLimit: "",
      overallPolicyDeductible: "",
      turnover: "",
      employees: "",
      saving: false,
      masterCoverVisible: false
    };
  },
  mixins: [FeaturesMixin],
  computed: {
    selectedTemplateCovers() {
      const template = this.$store.getters.selectedTemplate;
      if (template) {
        return template.covers;
      } else {
        return [];
      }
    },
    templateNames() {
      return this.$store.getters.templateNames;
    },
    selectedTemplateName() {
      const template = this.$store.getters.selectedTemplate;
      if (template) {
        return template.name;
      } else {
        return "";
      }
    },
    sublimitColspan() {
      const template = this.$store.getters.selectedTemplate;
      if (!template) {
        return 2;
      }
      return (
          (template.occurrenceVisible ? 1 : 0) +
          (template.aggregateVisible ? 1 : 0)
      );
    },
    deductibleColspan() {
      const template = this.$store.getters.selectedTemplate;
      return (
          (template.maxVisible ? 1 : 0) +
          (template.minVisible ? 1 : 0) +
          (template.percentageVisible ? 1 : 0)
      );
    }
  },
  methods: {
    changeTemplate(value) {
      this.$store.dispatch("changeTemplate", { newTemplate: value });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    }
  },
  mounted() {
    // Redirect to Programme Instructions tab if we don't have the data for this tab
    if (!this.$store.getters.programmeInstructionsActive) {
      this.$router.replace({ name: "Programme Instructions" });
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    const tab = document.getElementById("myTab");
    if (tab) {
      tab.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>
